.footer-div {
    height: 180px;
    background-color: #d6c8d8;
}

.copyright-div {
    height: 30px;
    background-color: #fba4dabb;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyright-div h1 {
    font-size: 12px;
}