.card-div {
    width: 60vw;
    height: 20vh;
    background-color: #fba4d9;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 20px;
    justify-content: center;
    position: relative;
}

.no-margin {
    margin-left: 14px;
    margin-right: 14px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.card-div h1 {
    font-size: 20px;
    font-weight: 800;
    color: white;
}

.bold {
    font-weight: 800;
}

.card-title-div {
    position: absolute;
    top: 0;
    background-color: #a18abe;
    width: 60vw;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.card-center-content {
    position: absolute;
    top: 6.5vh;
    display: flex;
    flex-flow: row;
    width: 100%;
    align-items: center;
}

.card-left-content {
    width: 50vw;
}

.some-height {
    margin-top: 20px;
}

.card-image-container {
    height: 50px;
    margin-left: 5vw;
    position: absolute;
    top: 20px;
    bottom: 0;
    right: 20px;
    width: 50px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-image {
    height: 30px;
    width: 30px;
}