html {
    font-size: 62.5%;
    font-family: 'Inter var', Roboto, Helvetica Neue, Arial, sans-serif;
    /* background-color: #121212; */
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
}

body {
    font-size: 13px;
    line-height: 1.4;
    overflow-x: hidden;
}

html, body, #root {
    position: relative;
    margin: 0;
    min-height: 100vh;
}

a {
    text-decoration: none;
}

img {
    -webkit-user-drag: none;
}

.background-container {
    width: 100%;
    height: 100%;
    background-image:  url("../public/wallpaper.jpg");
    background-size: 100px 100px;
    /* opacity: 0.2; */
}