#who-am-i {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.who-am-i-div {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: calc(100vh - 60px);
    justify-content: center;
}

.profile-image {
    width: 50vh;
    margin-right: 20px;
}

.profile-infos {
    width: 65vh;
    height: 30vh;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
}

.profile-infos h1 {
    color: #a18abe;
    font-size: 40px;
}