@import "~font-awesome/css/font-awesome.css";

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: left;
    max-width: 360px;
    width: 90%;
    position: relative;
}

.close-button {
    position: absolute;
    top: 5px;
    right: 15px;
    cursor: pointer;
    font-size: 25px;
    color: #777;
}

.modal-content h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
}

.modal-content .contact-item {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.modal-content .contact-item i {
    font-size: 24px;
    margin-right: 10px;
    color: #fba4d9;
}

.modal-content .contact-item h2 {
    font-size: 18px;
    margin: 0;
    color: #555;
}