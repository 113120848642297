.home-div {
    display: flex;
    flex-flow: column;
    align-items: center;
    height: calc(100vh + 8vh);
}

.home-image {
    width: 60vw;
    height: 60vh;
    object-fit: cover;
    margin-top: 15px;
}

.home-title {
    color: #a18abe;
    margin-bottom: -30px;
}

.subtitle-div {
    width: 50vw;
    margin-top: 40px;
    margin-bottom: -15px;
}

h2 {
    font-size: 14px;
    font-weight: 500;
}