.portfolio-div {
    display: flex;
    flex-flow: column;
    align-items: center;
    height: calc(100vh + 100vh);
}

.portfolio-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}